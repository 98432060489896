/*
===============================================================================
SPECIFIC DEFINITION - CUSTOMER SERVICE SECTION
-------------------------------------------------------------------------------
Specification of wrappers for modules, helper layout wrappers,
nonstandard sizes, etc.
===============================================================================
*/


/* Customer Service section modules specific styles
-------------------------------------------------------------------------------*/
.ui-dashboard {
    border-top: 1px solid var(--grey-300);
}

.ui-article-content .heading, .ui-page-content .heading {
    border-bottom: 1px solid var(--grey-300);
}
.ui-page-content .heading {
    padding-bottom: 20px;
}
.ui-article-content .thick-box {
    border: 10px solid #f2f2f2;
    text-align: center;
    clear: both;
    padding: 0;
}
.ui-article-content .thick-box .heading {
    margin-top: 30px;
    border: 0;
    text-transform: none;
    padding: 0;
}
.ui-article-content .thick-box .loyalty-program {
    max-width: 95%;
    height: 100%;
}
.ui-article-content .thick-box .intro-text {
    margin-top: 0;
    font-size: var(--font-size-body1);
	padding: 0 10px;
}
.ui-article-content .title {
    font-size: var(--font-size-heading1);
    padding-top: 16px;
    font-family: SansOri, SansOriFallback, sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
}

.email-form .w-control {
    clear : none;
    float: left;
}

.email-form .report-text {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 12px;
}

.email-form .w-buttons .k-button {
    float: right;
    padding-left: 40px;
    padding-right: 40px;
}

@media only screen and (max-width: 767px) {
        .email-form .form-bottom {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }

    .email-form .form-bottom .report-text {
        margin: 0;
    }

    .email-form .w-buttons .k-button {
        float: none;
    }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
    .email-form .form-bottom .report-text {
        width: 100%;
    }

    .email-form .w-buttons {
        width: 100%;
    }
}

.ui-form .k-widget {
    margin-top: 0;
}

.intro {
    text-align: center;
    font-size: var(--font-size-subtitle1);
    margin-bottom: 25px;
}

.ui-page-content .intro {
    margin-bottom: 25px;
}

/* Welcome page */
.category {
    text-transform: uppercase;
}
.ui-contact-us {
    border: 1px solid var(--grey-300);
    clear: both;
    overflow: hidden;
    margin: 0px 0px 50px 0px;
}
.ui-contact-us .subheading {
    font-family: SansOri, SansOriFallback, sans-serif;
    text-transform: uppercase;
    font-size: var(--font-size-h5);
    margin-bottom: 2px;
}
.ui-press-news-header .intro-text {
    font-size: var(--font-size-h6);
    margin-bottom: 30px;
}
.ui-press-news-header .title {
    font-size: var(--font-size-heading1);
    font-weight: normal;
    padding-top: 15px;
}
.ui-contact-us .heading {
    font-size: var(--font-size-caption);
}
.ui-contact-us .intro p:last-of-type {
    color: #919191;
}
.ui-contact-us .heading,
.ui-contact-us .subheading {
    text-transform: uppercase;
}
.ui-contact-us {
    border: 1px solid var(--grey-300);
    clear: both;
    overflow: hidden;
    margin: 50px 0 40px;
}
.ui-contact-us .contact-us-box {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 390px;
    height: 310px;
}
.ui-contact-us .contact-us-box p {
    margin: 0;
}
.ui-contact-us .contact-us-box .email {
    display: block;
    margin-bottom: 50px;
}

.ui-dictionary {
    margin: 25px 0;
}
.ui-dictionary .term {
    border-bottom: 2px solid var(--grey-100);
    margin-bottom: 15px;
}
.ui-dictionary .term:last-child {
    border-bottom: none;
}
.ui-dictionary .term .category {
    font-size: var(--font-size-caption);
    line-height: 1.17;
    color: var(--text-disabled);
}
.ui-dictionary .term .heading {
    text-transform: none;
    border-bottom: none;
    background: none;
    font-size: var(--font-size-h6);
    font-weight: bold;
    margin-bottom: 17px;
    padding: 0;
}
.ui-dictionary .term .category + .heading {
    margin-top: 1px;
}
.ui-dictionary .heading.faq {
    border-bottom: none;
    text-transform: uppercase;
}
.ui-dictionary .term .content {
    margin-bottom: 15px;

}
.ui-dictionary .term .content p:last-child {
    margin-bottom: 0;
}

/* New customer service design faq */
.new.ui-dictionary {
    border-bottom: none;
}

.new.ui-dictionary .category {
    display: none;
}

.new.ui-dictionary .headline {
    font-size: var(--font-size-h4);
    margin-bottom: 30px;
}

.new.ui-dictionary .term {
    padding: 0;
    border-top: 0;
}

.ui-dictionary .term .heading .question {
    margin: 0;
}

.ui-dictionary .term .b-icon::before {
    content: '\22c1';
    display: inline-block;
    float: right;
    font-family: 'IconsOri';
    font-size: var(--font-size-icon3);
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    transition: transform .2s ease-in;
}

.ui-dictionary .term .expanded.b-icon::before {
    transform: rotate(180deg);
}

.ui-suggestion .heading {
    font-size: var(--font-size-h5);
}

/* Default over writings */
.image-F {
    float: left;
}

/* Customer Service - Call Us section
-------------------------------------------------------------------------------*/
.ui-call-us {
    float: left;
    margin-top: 30px;
}